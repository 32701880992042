<template>
  <master-layout>
    <div v-if="showstream">
      <qr-stream :camera="camera" @decode="onDecode" @init="onInit" class="qrcodereader" ref="qrStreamRef" />
    </div>
  </master-layout>
</template>

<script>
import axios from 'axios'
import { QrStream } from 'vue3-qr-reader'
import { mapGetters, mapActions } from 'vuex'
import {
  loadingController
} from '@ionic/vue'
import store from '../store/index'

export default {
  name: 'CheckinWeb',
  components: {
    QrStream
  },
  data () {
    return {
      camera: 'off',
      result: null,
      showScanConfirmation: false,
      showstream: true,
      cameraOnButton: true,
      loading: null
    }
  },
  computed: {
    ...mapGetters('wineries', {
      wineryInMemory: 'getWineryInMemory'
    })
  },
  created () {
    this.createLoading()
  },
  beforeRouteEnter(to, from, next) {
    console.log(to + from + next)
    let wineryInMemory = store.getters['wineries/getWineryInMemory']
    if(wineryInMemory){
      return next({ path: '/app/confirm' })
    } else {
      return next()
    }
  },
  ionViewWillEnter () {
    this.camera = 'auto'
    this.showstream = true
  },
  ionViewWillLeave () {
    this.camera = 'off'
    this.showstream = false
  },
  methods: {
    ...mapActions('wineries', {
      setWineryInMemory: 'setWineryInMemory'
    }),

    async onInit (promise) {
      try {
        await promise
      } catch (e) {
        console.error(e)
      } finally {
        this.showScanConfirmation = this.camera === 'off'
      }
    },

    async cameraOn () {
      this.camera = 'auto'
      this.showstream = true
    },

    async onDecode (urlFromScan) {
      if(urlFromScan != urlFromScan.replace('https://www.orszagosbortura.hu/boraszatok/', '')){
        // this.presentLoading()
        await this.loading.present()
        this.camera = 'off'
        var wineryId = urlFromScan.replace('https://www.orszagosbortura.hu/boraszatok/', '').replace('/', '')
        this.showstream = false
        this.showScanConfirmation = false
        this.camera = 'off'
        this.showstream = false
        this.getWinery(wineryId)
      } else {
        alert('Ez nem Országos Bortúra QR-kód. Próbálj ki egy másik kódot!')
      }
    },

    async getWinery (wineryId) {
      var thisWinery = await axios.get(process.env.VUE_APP_STRAPI_URI + 'wineries/' + wineryId)
      this.setWineryInMemory(thisWinery.data)
      await this.loading.dismiss()
      this.$router.push('/app/confirm')
    },

    // async presentLoading() {
    //   this.loading = await loadingController
    //     .create({
    //       spinner: 'lines'
    //     })
    //   await this.loading.present()
    // }
    async createLoading() {
      this.loading = await loadingController
        .create({
          spinner: 'lines'
        })
    }
  }
}
</script>
